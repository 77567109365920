import { gql } from '@apollo/client';
import { SALES_REGION_FRAGMENT } from './SALES_REGION_FRAGMENT';

export const CREATE_OR_UPDATE_SALES_REGION_MUTATION = gql`
  mutation CreateOrUpdateSalesRegion($data: SalesRegionInput!) {
    mcp {
      createOrUpdateSalesRegion(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...SalesRegionFragment
        }
      }
    }
  }
  ${SALES_REGION_FRAGMENT}
`;
