import { gql } from '@apollo/client';

export const SALES_REGION_LIST_QUERY = gql`
query SalesRegionList($page: Int!, $take: Int!, $filter:String) {
  mcp {
    salesRegionList(page: $page, take: $take, filter:$filter) {
      total
      data {
        id
        cd
        name
        workGroupId
        description
      }
    }
  }
}
`;
