import { gql } from '@apollo/client';

export const CREATE_SHIPPER_MUTATION = gql`
mutation CreateOrUpdateShipper($data: ShipperInput!){
    md{
      createOrUpdateShipper(data: $data){
        success
        message{
          code
          message
        }
        content{
           id
        cd
        name
        phone11
        email
        citizenIdentityCard
        photoId
        address1
        distributorId
        distributor{
          cd
          name
        }
        photo{
          id
          url
        }
        }
      }
    }
  }
`;
