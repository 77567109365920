import { gql } from '@apollo/client';

export const DELETE_ATTR_MUTATION = gql`
  mutation DeleteAttr($id: Int!) {
    md {
      deleteAttr(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
