import { gql } from '@apollo/client';

export const DELETE_WORK_GROUP_MUTATION = gql`
mutation DeleteWorkGroup($id: Int!) {
    md {
      deleteWorkGroup(id: $id) {
        success
        message {
          code
          message
        }
        content
      }
    }
  }
`;
