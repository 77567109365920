import { gql } from '@apollo/client';
import { SALES_ORDER_FRAGMENT } from './SALES_ORDER_FRAGMENT';

export const CREATE_OR_UPDATE_SALES_ORDER_MUTATION = gql`
  mutation CreateOrUpdateSalesOrder($data: SalesOrderInput!) {
    o {
      createOrUpdateSalesOrder(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...SalesOrderFragment
        }
      }
    }
  }
  ${SALES_ORDER_FRAGMENT}
`;
