import gql from 'graphql-tag';

export const KPI_FORMULA_FRAGMENT = gql`
  fragment KpiFormulaFragment on KpiFormula {
    __typename
    id
    status
    name
    description
    formula
    amount
    percentage
    expiresBackDate
    attrDetail {
      id
      value
      description
    }
    kpiFormulaCategories {
      id
      attrDetailId
      percentage
      attrDetail {
        id
        value
        description
      }
    }
    kpiFormulaProducts {
      id
      rate
      productId
    }
  }
`;
