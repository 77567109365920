import { gql } from '@apollo/client';

export const MCP_DETAILS_QUERY = gql`
  query McpDetails($mcpId: Int!, $visitDate: Date!) {
    mcp {
      mcpDetails(mcpId: $mcpId, visitDate: $visitDate) {
        id
        visitIndex
        mcpLine {
          id
          weekdays
          weeksOfMonth
          customer {
            cd
            id
            name
          }
        }
      }
    }
  }
`;
