import gql from 'graphql-tag';

export const VISUAL_FRAGMENT = gql`
  fragment VisualFragment on Visual {
    __typename
    id
    cd
    status
    name
    description
    startDate
    endDate
    voteRequired
    minPhotos
    maxPhotos
    photo {
      id
      url
    }
    trade {
      id
      description
      startDate
      endDate
    }
    visualApplicableSalesRegions {
      id
      salesRegion {
        id
        name
      }
    }
  }
`;
