import { gql } from '@apollo/client';
import { SURVEY_FRAGMENT } from './SURVEY_FRAGMENT';

export const SURVEY_QUERY = gql`
  query Survey($id: Int!) {
    p {
      survey(id: $id) {
        ...SurveyFragment
      }
    }
  }
  ${SURVEY_FRAGMENT}
`;
