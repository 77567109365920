import { gql } from '@apollo/client';
import { DISTRIBUTOR_FRAGMENT } from './DISTRIBUTOR_FRAGMENT';

export const DISTRIBUTOR_QUERY = gql`
  query Distributor($id: Int!) {
    md {
      distributor(id: $id) {
        ...DistributorFragment
      }
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;
