import { gql } from '@apollo/client';

export const DISTRICTS_QUERY = gql`
  query Districts($provinceId: Int!) {
    md {
      districts(provinceId: $provinceId) {
        id
        name
      }
    }
  }
`;
