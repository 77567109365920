import gql from 'graphql-tag';

export const ATTR_FRAGMENT = gql`
  fragment AttrFragment on Attr {
    __typename
    id
    cd
    name
    description
    attrDetails {
      id
      value
      description
    }
  }
`;
