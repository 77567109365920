import { gql } from '@apollo/client';

export const COMMANDO_LIST_QUERY = gql`
  query CommandoList(
    $page: Int!
    $take: Int!
    $status: ProgramStatus
    $salesmanCd: String
    $commandoCd: String
  ) {
    mcp {
      commandoList(
        page: $page
        take: $take
        status: $status
        salesmanCd: $salesmanCd
        commandoCd: $commandoCd
      ) {
        total
        data {
          id
          cd
          status
          sellTypes
          description
          inRangeMeter
          hasOrderRequiredTasks
          allowOutPlanVisit
          allowNewCustomerVisit
          startDate
          endDate
          salesRegion {
            id
            name
          }
          salesman {
            id
            cd
            name
          }
        }
      }
    }
  }
`;
