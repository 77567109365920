import { gql } from '@apollo/client';
import { ATTR_FRAGMENT } from './ATTR_FRAGMENT';

export const CREATE_OR_UPDATE_ATTR_MUTATION = gql`
  mutation CreateOrUpdateAttr($data: AttrInput!) {
    md {
      createOrUpdateAttr(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...AttrFragment
        }
      }
    }
  }
  ${ATTR_FRAGMENT}
`;
