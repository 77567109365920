import gql from 'graphql-tag';

export const SALES_REGION_FRAGMENT = gql`
  fragment SalesRegionFragment on SalesRegion {
    __typename
    id
    cd
    name
    workGroupId
    description
    salesRegionAreas {
      id
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
    }
  }
`;
