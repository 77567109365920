import gql from 'graphql-tag';

export const KPI_CONFIG_FRAGMENT = gql`
  fragment KpiConfigFragment on KpiConfig {
    __typename
    id
    cd
    status
    target
    name
    description
    startDate
    endDate

    kpiConfigItems {
      id
      actual
      target
      rate
      percentageActual
      employee {
        id
        cd
        name
      }
      kpiFormula {
        id
        cd
        name
      }
      distributor {
        id
        cd
        name
      }
    }
  }
`;
