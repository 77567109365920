import gql from 'graphql-tag';

export const MCP_FRAGMENT = gql`
  fragment McpFragment on Mcp {
    __typename
    id
    cd
    status
    sellTypes
    description
    inRangeMeter
    hasOrderRequiredTasks
    allowOutPlanVisit
    allowNewCustomerVisit
    startDate
    endDate
    salesRegion {
      id
      cd
      name
    }
    creator {
      id
      cd
      name
    }
    salesman {
      id
      cd
      name
    }
  }
`;
