import { gql } from '@apollo/client';
import { TRANSACTION_FRAGMENT } from './TRANSACTION_FRAGMENT';

export const TRANSACTION_QUERY = gql`
  query Transaction($id: Int!, $source: TransactionSource) {
    in {
      transaction(id: $id, source: $source) {
        ...TransactionFragment
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
