import { gql } from '@apollo/client';

export const DELETE_SHIPPER_MUTATION = gql`
mutation DeleteShipper($id:Int!){
    md{
      deleteShipper(id: $id){
        success
        message{
          code
          message
        }
        content
      }
    }
  }
`;
