import { gql } from '@apollo/client';

export const DISTRIBUTOR_LIST_QUERY = gql`
  query DistributorList(
    $page: Int!
    $take: Int!
    $filter: String
    $provinceId: Int
    $districtId: Int
    $wardId: Int
  ) {
    md {
      distributorList(
        page: $page
        take: $take
        filter: $filter
        provinceId: $provinceId
        districtId: $districtId
        wardId: $wardId
      ) {
        total
        data {
          id
          cd
          name
          contactName1
          phone11
          isActive
          distributorType {
            id
            cd
            name
          }
          customer {
            id
            name
            customerPriceClassId
          }

          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
