import { gql } from '@apollo/client';

export const PRODUCT_PRICES_QUERY = gql`
  query ProductPrices($date: Date, $customerPriceClassId: Int) {
    productPrices(date: $date, customerPriceClassId: $customerPriceClassId) {
      id
      productId
      unitId
      price
    }
  }
`;
