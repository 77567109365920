import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
mutation Login($data: LoginInput!, $deviceUser: DeviceUserInput) {
  login(data: $data, deviceUser: $deviceUser) {
    success
    message {
      code
      message
    }
    content {
      accessToken
    }
  }
}
`;
