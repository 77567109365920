import gql from 'graphql-tag';

export const EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeFragment on Employee {
    __typename
    id
    cd
    name
    username
    email
    address1
    isActive
    phone11
    startDate
    endDate
    supervisor {
      id
      cd
      name
    }
    citizenIdentityCard
    photo {
      id
      url
    }

    ward {
      id
      name
      district {
        id
        name
        province {
          id
          name
        }
      }
    }

    employeePositions {
      id
      position {
        id
        name
      }
    }

    workingDistributors {
      id
      distributor {
        id
        cd
        name
      }
    }

    workingSalesRegions {
      id
      salesRegion {
        id
        name
      }
    }
  }
`;
