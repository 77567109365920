import { gql } from '@apollo/client';
import { VISUAL_FRAGMENT } from './VISUAL_FRAGMENT';

export const VISUAL_QUERY = gql`
  query Visual($id: Int!) {
    p {
      visual(id: $id) {
        ...VisualFragment
      }
    }
  }
  ${VISUAL_FRAGMENT}
`;
