import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { UiProvider } from './contexts';
import { authLink, cache, client, DEFAULT_API_URL } from './graphql-client';
import { store } from './utils/store';

export default function Providers({ children }: React.PropsWithChildren<{}>) {
  const [clientConfig, setClientConfig] = useState(client);

  const loadServer = async () => {
    const baseUrl = await store.getBaseUrl();

    const httpLink = createHttpLink({
      uri: `${baseUrl || DEFAULT_API_URL}/graphql`,
    });

    const clientSelect = new ApolloClient({
      link: authLink.concat(httpLink) as any,
      cache,
    });
    setClientConfig(clientSelect);
  };

  useEffect(() => {
    loadServer();
  }, []);
  return (
    <ApolloProvider client={clientConfig}>
      <UiProvider>{children}</UiProvider>
    </ApolloProvider>
  );
}
