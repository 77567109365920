import { CNavLink } from '@coreui/react';
import { theme } from '../types/theme';

export const TabLink = ({
  href,
  index,
  activeIndex,
  setIndex,
  hasErrors,
  children,
}: {
  href?: string;
  index: number;
  activeIndex: number;
  setIndex: (i: number) => void;
  hasErrors?: any;
  children: any;
}) => (
  <CNavLink
    active={index === activeIndex}
    style={{ color: index === activeIndex ? theme.colors.normalText : theme.colors.iconGrey }}
    href={href || '#'}
    className={!!hasErrors ? 'border-danger border-bottom-0' : undefined}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setIndex(index);
    }}
  >
    {children}
  </CNavLink>
);
