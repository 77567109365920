import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_COMMANDO_LINE_MUTATION = gql`
  mutation createOrUpdateCommandoLine($data: CommandoLineInput!) {
    mcp {
      createOrUpdateCommandoLine(data: $data) {
        success
        message {
          code
          message
        }
        content {
          id
          distributor {
            id
            cd
            name
          }
          visitDate
          addedAt
          startHour
          endHour
          targetAmount
          targetCustomer
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
