import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

export const Submitting = () => {
  return (
    <div
      className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
      style={{ top: 0, left: 0, backgroundColor: '#00000020', zIndex: 1000 }}
    >
      <HashLoader color="#1CCCAA" />
    </div>
  );
};
