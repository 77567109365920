import { DISCOUNT_FRAGMENT } from './DISCOUNT_FRAGMENT';
import { gql } from '@apollo/client';

export const CUSTOMER_DISCOUNTS_QUERY = gql`
query CustomerDiscounts($customerId: Int!) {
  p {
    customerDiscounts(customerId: $customerId) {
      ...DiscountFragment
    }
  }
}
${DISCOUNT_FRAGMENT}
`;
