import gql from 'graphql-tag';

export const CHECKOUT_REASON_FRAGMENT = gql`
  fragment CheckOutReasonFragment on CheckOutReason {
    __typename
    id
    name
    minutes
    description
    requiredTasks
    isVisit
  }
`;
