import gql from 'graphql-tag';

export const TRADE_FRAGMENT = gql`
  fragment TradeFragment on Trade {
    __typename
    id
    cd
    description
    status
    breakBy
    multiple
    autoLevel
    registerStartDate
    registerEndDate
    startDate
    endDate

    tradeProducts {
      id
      productId
      rate
    }
    tradeProductGroups {
      id
      productGroupId
    }
    tradeLevels {
      id
      level
      description
      breakValue
      amount
      percentage
      qty
      isBundle
      tradeLevelProducts {
        id
        productId
        rate
      }
    }
    tradeApplicableCustomers {
      id
      customer {
        id
        cd
        name
      }
    }
    tradeApplyTarget
    tradeApplicableDistributors {
      id
      distributor {
        id
        cd
        name
      }
    }
    tradeApplicableCustomerAttrs {
      id
      attrId
      attrDetailId
    }
    tradeApplicableSalesRegions {
      id
      salesRegion {
        id
        name
      }
    }
  }
`;
