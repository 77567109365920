import { gql } from '@apollo/client';
import { PURCHASE_RECEIPT_FRAGMENT } from './PURCHASE_RECEIPT_FRAGMENT';

export const PURCHASE_RECEIPT_QUERY = gql`
  query PurchaseReceipt($id: Int!) {
    o {
      purchaseReceipt(id: $id) {
        ...PurchaseReceiptFragment
      }
    }
  }
  ${PURCHASE_RECEIPT_FRAGMENT}
`;
