import { gql } from '@apollo/client';

export const WORKING_CUSTOMER_LIST_QUERY = gql`
  query WorkingCustomerList(
    $page: Int!
    $take: Int!
    $distributorId: Int!
    $filter: String
    $isDistributor: Boolean
  ) {
    workingCustomerList(
      page: $page
      take: $take
      distributorId: $distributorId
      filter: $filter
      isDistributor: $isDistributor
    ) {
      total
      data {
        id
        cd
        name
        customerPriceClassId
        isDistributor
      }
    }
  }
`;
