import { gql } from '@apollo/client';
import { CHECKOUT_REASON_FRAGMENT } from './CHECKOUT_REASON_FRAGMENT';

export const CHECKOUT_REASONS_QUERY = gql`
  query CheckOutReasons {
    mcp {
      checkOutReasons {
        ...CheckOutReasonFragment
      }
    }
  }
  ${CHECKOUT_REASON_FRAGMENT}
`;
