import { gql } from '@apollo/client';

export const PROVINCES_QUERY = gql`
  query Provinces {
    md {
      provinces {
        id
        name
      }
    }
  }
`;
