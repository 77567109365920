import { gql } from '@apollo/client';

export const SALES_ORDER_LIST_QUERY = gql`
  query SalesOrderList(
    $page: Int!
    $take: Int!
    $startDate: Date
    $endDate: Date
    $status: OrderStatus
    $distributorCd: String
    $customerCd: String
    $orderType: OrderType
    $sellType: SellType
    $salesOrderCd: String
    $salesmanCd: String
  ) {
    o {
      salesOrderList(
        page: $page
        take: $take
        status: $status
        startDate: $startDate
        endDate: $endDate
        salesmanCd: $salesmanCd
        distributorCd: $distributorCd
        customerCd: $customerCd
        orderType: $orderType
        sellType: $sellType
        salesOrderCd: $salesOrderCd
      ) {
        total
        data {
          id
          cd
          orderDate
          orderType
          sellType
          total
          discountMoney
          orderStatus
          orderSource
          orderTotal
          description
          creator {
            id
            cd
            name
          }
          customer {
            id
            cd
            name
            address1
            ward {
              id
              name
              district {
                id
                name
                province {
                  id
                  name
                }
              }
            }
          }
          distributor {
            id
            cd
            name
          }
          salesman {
            cd
            name
          }
        }
      }
    }
  }
`;