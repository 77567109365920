export const store = {
  getAccessToken: async () => localStorage.getItem('accessToken'),
  setAccessToken: async (accessToken: string) => localStorage.setItem('accessToken', accessToken),
  removeAccessToken: async () => localStorage.removeItem('accessToken'),
  getBaseUrl: async () => localStorage.getItem('baseUrl'),
  setBaseUrl: async (baseUrl: string) => localStorage.setItem('baseUrl', baseUrl),
  removeBaseUrl: async () => localStorage.removeItem('baseUrl'),
  getHeadOffice: async () => localStorage.getItem('headOffice'),
  setHeadOffice: async (headOfficeName: string) => localStorage.setItem('headOffice', headOfficeName),
  removetHeadOffice: async () => localStorage.removeItem('headOffice'),
};
