import { CCol, CFormLabel } from '@coreui/react';
import React from 'react';

export const RequireLabel = ({
  id,
  text,
  htmlFor,
  required,
}: {
  id?: string;
  text: string;
  htmlFor?: string;
  required?: boolean;
}) => {
  return (
    <CCol>
      <CFormLabel id={id} className="col-form-label" htmlFor={htmlFor}>
        {text}
      </CFormLabel>
      {required && (
        <CFormLabel className="col-form-label" style={{ color: '#DC143C' }}>
          *
        </CFormLabel>
      )}
    </CCol>
  );
};
