import { gql } from '@apollo/client';
import { TRANSACTION_FRAGMENT } from './TRANSACTION_FRAGMENT';

export const CREATE_OR_UPDATE_TRANSACTION_MUTATION = gql`
  mutation CreateOrUpdateTransaction($data: TransactionInput!) {
    in {
      createOrUpdateTransaction(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...TransactionFragment
        }
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
