import { ApolloError } from '@apollo/client';
import React from 'react';
import { PayloadMessage } from '../queries';

export const FormSummary = ({
  error,
  message,
  className,
}: {
  error?: ApolloError;
  message?: PayloadMessage | null;
  className?: string;
}) => {
  return (
    <>
      {error && (
        <div className={`text-danger ${className}`}>
          {error.message || 'Something went wrong, please try again later.'}
        </div>
      )}
      {message && (
        <div className={`text-danger ${className}`}>
          {message.message || 'Something went wrong, please try again later.'}
        </div>
      )}
    </>
  );
};
