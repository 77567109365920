import { gql } from '@apollo/client';
import { DISCOUNT_FRAGMENT } from './DISCOUNT_FRAGMENT';

export const CREATE_OR_UPDATE_DISCOUNT_MUTATION = gql`
  mutation CreateOrUpdateDiscount($data: DiscountInput!) {
    p {
      createOrUpdateDiscount(data: $data) {
        success
        message {
          code
          message
        }
        content {
          ...DiscountFragment
        }
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;
