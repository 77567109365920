import { gql } from '@apollo/client';

export const SHIPPER_LIST_QUERY = gql`
    query ShipperList($page:Int!, $take:Int!, $filter:String, $distributorId:Int){
        md{
        shipperList(page: $page, take: $take, filter: $filter, distributorId: $distributorId){
            total
            data{
            id
            cd
            name
            phone11
            email
            citizenIdentityCard
            address1
            isAlive
            photoId
            distributor{
                cd
                name
            }
            photo{
            id
            url
            }
            }
        }
        }
    }
`;
