import { gql } from '@apollo/client';
export const SELLERS_DISTRIBUTOR_LIST_QUERY = gql`
  query SellersDistributorList($distributorId: Int!, $filter: String) {
    sellersDistributorList(distributorId: $distributorId, filter: $filter) {
      total
      data {
        id
        cd
        name
      }
    }
  }
`;
