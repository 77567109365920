import React, { lazy, Suspense, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FormSummary } from './components';
import { DEFAULT_API_URL } from './graphql-client';
import Providers from './Providers';
import { useMeQuery } from './queries';
import './scss/style.scss';
import { store } from './utils';
import { ChangeServerModal } from './views/login/ChangeServerModal';

const Login = lazy(() => import('./views/login/Login'));
const Layout = lazy(() => import('./layout/Layout'));

const Loading = () => <Skeleton className="w-100" count={10} />;

const Auth = () => {
  const { data, loading, error } = useMeQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [visibleChangeServer, setVisibleChangeServer] = useState(false);
  const authenticated = !!data?.me;
  const [unauthentidated, setUnauthentidated] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const loadBaseUrl = async () => {
    const baseUrlLoad = await store.getBaseUrl();
    setBaseUrl(`${baseUrlLoad || DEFAULT_API_URL}/graphql`);
  };

  useEffect(() => {
    loadBaseUrl();
  }, []);

  useEffect(() => {
    if (error?.message === 'Failed to fetch') {
      store.removeAccessToken();
    }
    if (error?.graphQLErrors?.[0]?.extensions?.code === 'DMS_UNAUTHENTICATED') {
      // should signout
      setUnauthentidated(true);
    }
  }, [error]);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            {authenticated && <Route path="/" component={Layout} />}
            {unauthentidated && <Route path="/" component={Login} />}
          </Switch>
        </Suspense>
      </BrowserRouter>
      {!unauthentidated && (
        <>
          <FormSummary error={error} className="p-3" />
          {!authenticated && (
            <label
              style={{ marginLeft: 5 }}
              onClick={() => {
                setVisibleChangeServer(true);
              }}
            >{`Server: ${baseUrl}`}</label>
          )}
        </>
      )}
      {loading && <Loading />}
      
      <ChangeServerModal visible={visibleChangeServer} setVisible={setVisibleChangeServer} />
    </>
  );
};


export const App = () => {
  return (
    <Providers>
      <Auth />
    </Providers>
  );
};
