import { gql } from '@apollo/client';
import { TRANSACTION_FRAGMENT } from './TRANSACTION_FRAGMENT';

export const PROCESS_TRANSACTION_MUTATION = gql`
  mutation ProcessTransaction($id: Int!, $source: TransactionSource!) {
    in {
      processTransaction(id: $id, source: $source) {
        success
        message {
          code
          message
        }
        content {
          ...TransactionFragment
        }
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;
