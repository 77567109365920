import { gql } from '@apollo/client';

export const EMPLOYEE_LIST_QUERY = gql`
  query EmployeeList($page: Int!, $take: Int!, $filter: String) {
    md {
      employeeList(page: $page, take: $take, filter: $filter) {
        total
        data {
          id
          cd
          name
          username
          email
          address1
          isActive
          citizenIdentityCard
          photo {
            id
            url
          }

          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }

          employeePositions {
            id
            position {
              id
              name
            }
          }
        }
      }
    }
  }
`;
