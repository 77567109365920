export const theme /*: Theme */ = {
  colors: {
    primary: '#2F85F7',
    primaryDark: '#215DAD',
    primaryLight: '#66CCFF',

    secondary: '#66FFCC',

    danger: '#f53d3d',
    success: '#28a745',
    lightGrey: '#DDDDDD',
    lightGrey1: '#828282',
    lightGrey2: '#828282',
    iconGrey: '#8992A4',

    light: '#fafafa',
    light2: '#F5F7F9',
    light3: '#F2F2F2',
    dark: '#222',

    normalText: '#273D52',
    lightText: '#aeaeae',
    darkText: '#334D6E',

    lightBackground: '#f6f6f6',
    transparent: 'transparent',

    border: '#DADDE0',
    separator: '#e0e0e0',
    muted: '#B4B6BC',
    txtColor: '#22222299',

    white: '#fff',
    black: '#000',
    lime: '#66CDAA',

    backdrop: 'rgba(0,0,0,.3)',
    backdrop2: 'rgba(0,0,0,.6)',
    clearColor: 'rgba(100, 100, 100, 0)',
    buttonAccept: '#0000DD',
    buttonReject: '#0000DD',

    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
  },
  fonts: {
    sans: 'Montserrat',
    sansBold: 'Montserrat-Bold',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  shadows: ['none', '0px 2px 8px rgba(20, 20, 30, 0.05)', '0px 7px 12px rgba(39, 61, 82, 0.05)'],
  fontSizes: [13, 14, 15, 20, 24, 36, 48, 80, 96],
  smallFontSizes: [12, 11, 10, 9, 8, 7, 6, 5],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  heights: [0, 16, 24, 32, 48, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  textShadows: {
    white: {
      textShadowColor: 'white',
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 8,
    },
    black: {
      textShadowColor: 'black',
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 8,
    },
  },
};

export const darkTheme /*: Theme */ = {
  ...theme,
  colors: {
    primary: '#f48232',
    primaryDark: '#c2570a',
    primaryLight: '#f7a76e',

    secondary: '#32db64',

    danger: '#f53d3d',
    success: '#28a745',

    light: '#222',
    dark: '#fafafa',

    lightGrey: '#DDDDDD',
    lightGrey1: '#828282',

    normalText: '#d8c2ad',
    lightText: '#515151',
    darkText: '#ccb291',

    lightBackground: '#090909',
    transparent: 'transparent',

    border: '#0d0d0d',
    separator: '#1f1f1f',
    muted: '#4b4943',

    white: '#000',
    black: '#fff',

    blacks: [
      'rgba(0,0,0,.9)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.0125)',
    ],
    whites: [
      'rgba(255,255,255,.9)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.0125)',
    ],
  },
};

export const cardStyle = { justifyContent: 'center', alignContent: 'center', alignSelf: 'center' };
export const cardContentStyle = {
  marginBottom: 20,
  justifyContent: 'center',
  alignContent: 'center',
  alignSelf: 'center',
};
export const tabStyle = {
  justifyContent: 'center',
  alignContent: 'center',
  alignSelf: 'center',
  width: 1000,
};
export const contentStyle = {
  paddingTop: 20,
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 20,
  minHeight: '75vh',
  backgroundColor: '#FFF',
};
export const tableTitleStyle = { fontSize: 30, alignSelf: 'center', border: 'hidden' };
export const tableTitleTableStyle = {
  fontSize: 16,
  borderRight: 'hidden',
  borderLeft: 'hidden',
  borderBottom: '1px solid black',
};
export const tableTdStyle = { fontSize: 14, border: 'hidden', paddingBottom: 0 };
export const tableTdContent = { fontSize: 14, border: '1px solid black', verticalAlign: 'middle' };
export const tableTdDataContent = {
  fontSize: 14,
  border: '1px solid black',
  verticalAlign: 'middle',
};
export const tableTdData = {
  fontSize: 14,
  border: '1px solid Silver',
  verticalAlign: 'middle',
};
export const tableTrDataContent = { border: '1px solid black' };
export const tableFooterStyle = { fontSize: 14, paddingTop: 20, border: 'hidden' };
export const tableTrStyle = { border: 'hidden' };
export const seperatorSpace = { height: 30 };
