import { gql } from '@apollo/client';
import { DISCOUNT_FRAGMENT } from './DISCOUNT_FRAGMENT';

export const DISCOUNT_QUERY = gql`
  query Discount($id: Int!) {
    p {
      discount(id: $id) {
        ...DiscountFragment
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;
