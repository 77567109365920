import { gql } from '@apollo/client';

export const RELEASE_KPI_FORMULA_MUTATION = gql`
  mutation ReleaseKpiFormula($id: Int!) {
    p {
      releaseKpiFormula(id: $id) {
        success
        message {
          code
          message
        }
        content {
          id
          status
        }
      }
    }
  }
`;
