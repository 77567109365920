import { gql } from '@apollo/client';
import { EMPLOYEE_FRAGMENT } from './EMPLOYEE_FRAGMENT';

export const EMPLOYEE_QUERY = gql`
  query Employee($id: Int!) {
    md {
      employee(id: $id) {
        ...EmployeeFragment
      }
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;
